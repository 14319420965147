import React, { useState } from 'react';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';

export interface ProductsTopTableProps {
  list: any[];
}

const ProductsTop: React.FC<ProductsTopTableProps> = ({ list }) => {
  const [paginationPage, setPaginationPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(5);

  const productsColumns = [
    {
      name: 'ID',
      selector: (row) => row.p_id,
      sortable: true,
      maxWidth: '8%',
      minWidth: '6%',
    },
    {
      name: 'Продано',
      selector: (row) => row.p_quantitySold,
      sortable: true,
      maxWidth: '8%',
      minWidth: '6%',
    },
    {
      name: 'Ціна',
      selector: (row) => row.p_price,
      sortable: true,
      maxWidth: '8%',
      minWidth: '6%',
    },
    {
      name: 'Назва',
      selector: (row) => row.p_name,
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'URL ключ',
      selector: (row) => row.p_url,
      minWidth: '250px',
    },
    {
      name: 'Рейтинг',
      selector: (row) => row.p_avgRating,
      maxWidth: '8%',
      minWidth: '6%',
    },
  ];

  const handlePageChange = (page: number) => {
    setPaginationPage(page);
  };

  const handleRowsPerPageChange = (newLimit: number) => {
    setPaginationLimit(newLimit);
    setPaginationPage(1);
  };

  return (
    <Card>
      <DataTable
        style={{ backgroundColor: '#000' }}
        data={list}
        columns={productsColumns}
        title={'Топ продажів'}
        pagination
        paginationPerPage={paginationLimit}
        paginationTotalRows={list.length}
        paginationDefaultPage={paginationPage}
        paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </Card>
  );
};

export default ProductsTop;
