import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, Select, MenuItem, FormControl, InputLabel, Box } from '@material-ui/core';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { Line } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { SentimentSatisfiedAlt } from '@material-ui/icons';
import moment from 'moment'; // old way REMOVE PACKAGE AFTER TASK WILL BE DONE!!!

import { COLORS } from '../../../values/colors';
import { getOrderStatisticByRangeRequest } from '../../../store/actions/orders.actions';
import { RootState, AppDispatch } from '../../../store/store';
import usePreviousWeekDateRange from '../../../hooks/usePreviousWeekDateRange';
import usePreviousMonthDateRange from '../../../hooks/usePrewiousMonthDateRange';
import usePreviousYearDateRange from '../../../hooks/usePreviousYearDateRange';
import usePreviousThreeMonthDateRange from '../../../hooks/usePreviousThreeMonthDateRange';
import DateSelectionModal from '../../Modals/DateSelectionModal/DateSelectionModal';

interface DashboardProps {
  data?: Array<any>;
}

const useStyles = makeStyles((theme) => ({
  paperLight: {
    color: COLORS.primaryGreen,
    height: '100%',
    paddingTop: '50px',
  },
  paperDark: {
    color: COLORS.primaryLight,
    height: '100%',
  },
  cardFlex: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
  dashboard: {
    border: 'none',
  },
  header: {
    display: 'flex',
    // minHeight: '75px',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    // marginBottom: theme.spacing(2),
  },
  headerLight: {
    backgroundColor: COLORS.secondaryOttoman,
    color: COLORS.fontColor,
  },
  headerDark: {
    backgroundColor: COLORS.darkGray,
    color: COLORS.primaryLight,
  },
  content: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
    marginLeft: theme.spacing(4),
  },
  gridWraper: {
    position: 'relative',
  },
  row: {
    border: 'none',
    marginBottom: '0',
  },
  row1card: {
    height: '120px',
  },
  row2card: {
    height: '345px',
  },
  row3card: {
    height: '430px',
  },
  cardTitleLight: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: COLORS.primaryGreen,
  },
  cardTitleDark: {
    fontSize: '13px',
    fontWeight: 'bold',
    color: COLORS.darkGreen,
  },
  cardValueLight: {
    fontSize: '29px',
    color: COLORS.darkTableColor,
  },
  cardValueDark: {
    fontSize: '29px',
    color: COLORS.primaryLight,
  },
  cardComment: {
    fontSize: '13px',
    color: COLORS.primaryLight,
  },
  cardIcon: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
  },
  cardIconLight: {
    color: COLORS.primaryLight,
  },
  cardIconDark: {
    color: COLORS.primaryGreen,
  },
  message: {
    height: '325px',
    textAlign: 'center',
    paddingTop: '100px',
  },
  selectList: {
    marginTop: '22px !important',
  },
  selectListLabel: {
    color: COLORS.primaryGreen,
    fontSize: '26px',
    fontWeight: 'bold',
  },
}));

const initialState = {
  totalLiqpayPaidOrders: 0,
  totalPostPaidOrders: 0,
  totalProcessingOrders: 0,
  totalCancelledOrders: 0,
  totalCompletedOrders: 0,
};
const options = {
  maintainAspectRatio: false,
  title: {
    display: true,
    text: 'Загальна кількість замовлень',
    fontColor: COLORS.darkGreen,
    fontSize: 18,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          min: 0,
        },
      },
    ],
  },
};

enum Period {
  THREEMONTH = 'three month',
  YEAR = 'year',
  MONTH = 'month',
  WEEK = 'week',
  TODAY = 'today',
  CUSTOM = 'custom',
  CHOOSE = 'choose',
}

const DashboardContent: React.FC<DashboardProps> = () => {
  const dispatch: AppDispatch = useDispatch();

  let ordersOfChoosePeriod = useSelector((state: RootState) => state.orders.rangeOrdersStatistic);
  const { user } = useSelector((state: RootState) => state.user);

  const { start: startOfPreviousWeek, end: endOfPreviousWeek } = usePreviousWeekDateRange();
  const { start: startOfPreviousMonth, end: endOfPreviousMonth } = usePreviousMonthDateRange();
  const { start: startOfPreviousYear, end: endOfPreviousYear } = usePreviousYearDateRange();
  const { start: startOfPreviousThreeMonth, end: endOfPreviousThreeMonth } = usePreviousThreeMonthDateRange();

  const [chartMovement, setChartMovement] = React.useState({});
  const [period, setPeriod] = useState(Period.THREEMONTH);
  const [totals, setTotals] = useState(initialState);
  const [start, setStart] = useState(startOfPreviousThreeMonth);
  const [end, setEnd] = useState(endOfPreviousThreeMonth);
  const [openCalendars, setOpenCalendars] = useState(false);
  const [selectValue, setSelectValue] = useState<Period | string>(period);

  const classes = useStyles();
  const { darkMode } = useSelector((state: RootState) => state.theme);
  let bgColor = !darkMode ? COLORS.primaryGreen : COLORS.darkGreen;

  const handlePeriodChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value as Period;
    if (Object.values(Period).includes(value)) {
      setPeriod(value);
      setSelectValue(value);
      choosePeriod(value);
    } else {
      setPeriod(Period.THREEMONTH);
    }
  };

  const calculateTotals = () => {
    return ordersOfChoosePeriod.reduce(
      (acc, entry) => ({
        totalLiqpayPaidOrders: acc.totalLiqpayPaidOrders + parseFloat(entry.liqpaypaidorders) || 0,
        totalPostPaidOrders: acc.totalPostPaidOrders + parseFloat(entry.postpaidorders) || 0,
        totalProcessingOrders: acc.totalProcessingOrders + parseFloat(entry.processingorders) || 0,
        totalCancelledOrders: acc.totalCancelledOrders + parseFloat(entry.cancelledorders) || 0,
        totalCompletedOrders: acc.totalCompletedOrders + parseFloat(entry.completedorders) || 0,
      }),
      initialState
    );
  };

  useEffect(() => {
    dispatch(getOrderStatisticByRangeRequest([startOfPreviousThreeMonth, endOfPreviousThreeMonth]));
  }, [startOfPreviousThreeMonth]);

  useEffect(() => {
    if (ordersOfChoosePeriod) {
      movementData(start, end);
      setTotals(calculateTotals());
    }
  }, [darkMode, start, end, ordersOfChoosePeriod]);

  useEffect(() => {
    if (!openCalendars && period === Period.CUSTOM) {
      setSelectValue(Period.CHOOSE);
    }
  }, [openCalendars]);

  const choosePeriod = (period: Period) => {
    let start = startOfPreviousThreeMonth;
    let end = endOfPreviousThreeMonth;

    switch (period) {
      case Period.THREEMONTH:
        start = startOfPreviousThreeMonth;
        end = endOfPreviousThreeMonth;
        break;
      case Period.YEAR:
        start = startOfPreviousYear;
        end = endOfPreviousYear;
        break;
      case Period.MONTH:
        start = startOfPreviousMonth;
        end = endOfPreviousMonth;
        break;
      case Period.WEEK:
        start = startOfPreviousWeek;
        end = endOfPreviousWeek;
        break;
      case Period.TODAY:
        start = DateTime.now().toISODate();
        end = DateTime.now().toISODate();
        break;
      case Period.CUSTOM:
        setOpenCalendars(true);
        break;
      default:
        start = startOfPreviousThreeMonth;
        end = endOfPreviousThreeMonth;
    }
    if (period !== Period.CUSTOM) {
      setStart(start);
      setEnd(end);
      dispatch(getOrderStatisticByRangeRequest([start, end]));
    }
  };

  const movementData = (start: string, end: string) => {
    let dateLabels: string[] = [];
    let dataByDate: string[] = [];
    const startDate = DateTime.fromISO(start);
    const endDate = DateTime.fromISO(end);
    let delayOfPeriod = endDate.diff(startDate, 'days').days;

    if (period === Period.TODAY) {
      delayOfPeriod = 24;

      for (let i = 0; i < delayOfPeriod; i += 1) {
        const hour = String(i);
        dateLabels.push(hour);
      }

      dataByDate = dateLabels.map((label) => {
        const correspondingData = ordersOfChoosePeriod.find((data) => {
          return DateTime.fromFormat(data.date, 'yyyy-MM-dd HH:mm:ss').toFormat('HH').toString() === label;
        });
        if (correspondingData && correspondingData.totalorders !== '0') {
          return correspondingData.totalorders;
        } else {
          return '0';
        }
      });
    }

    if (period !== Period.TODAY) {
      for (let i = 0; i < delayOfPeriod; i += 1) {
        const day = DateTime.fromISO(start).plus({ days: i });
        dateLabels.push(day.toFormat('d.MM.yyyy').toString());
      }

      dataByDate = dateLabels.map((label) => {
        const correspondingData = ordersOfChoosePeriod.find((data) => {
          return DateTime.fromFormat(data.date, 'yyyy-MM-dd HH:mm:ss').toFormat('d.MM.yyyy') === label;
        });

        if (correspondingData && correspondingData.totalorders !== '0') {
          return correspondingData.totalorders;
        } else {
          return '0';
        }
      });
    }

    setChartMovement({
      labels: dateLabels,
      datasets: [
        {
          label: '',
          data: dataByDate,
          backgroundColor: bgColor,
          borderWidth: 4,
        },
      ],
    });
  };

  const setCustomDate = (start: string, end: string) => {
    setStart(start);
    setEnd(end);
    dispatch(getOrderStatisticByRangeRequest([start, end]));
  };

  const firstRow = [
    {
      title: 'Оплата Liqpay',
      value: totals.totalLiqpayPaidOrders.toString(),
    },
    {
      title: 'Післяплата',
      value: totals.totalPostPaidOrders.toString(),
    },
    {
      title: 'Замовлення в обробці',
      value: totals.totalProcessingOrders.toString(),
    },
    {
      title: 'Скасовані замовлення',
      value: totals.totalCancelledOrders.toString(),
    },
    {
      title: 'Завершені замовлення',
      value: totals.totalCompletedOrders.toString(),
    },
  ];

  return (
    <div className={classes.dashboard}>
      <div className={clsx(classes.header, darkMode ? classes.headerDark : classes.headerLight)}>
        <div>
          {' '}
          <h3>Welcome back, {user?.firstName}!</h3>
        </div>
      </div>
      <div className={classes.content}>
        <Grid container className={classes.gridWraper} spacing={2}>
          <Grid item container direction="row" justifyContent="center" spacing={3}>
            {firstRow.map(({ title, value }) => (
              <Grid item className={classes.row1card} key={title}>
                <Paper className={clsx(classes.paperLight, classes.cardFlex)}>
                  <Typography className={!darkMode ? classes.cardTitleLight : classes.cardTitleDark}>
                    {title}
                  </Typography>
                  <Typography className={!darkMode ? classes.cardValueLight : classes.cardValueDark}>
                    {value}
                  </Typography>
                  <CheckCircleOutlineOutlinedIcon
                    fontSize="medium"
                    className={clsx(
                      classes.cardIcon,
                      darkMode ? classes.cardIconLight : classes.cardIconDark
                    )}
                  />
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Grid item container justifyContent="space-between" className={classes.row} spacing={3}>
            <Grid item container>
              <Grid item xs={12} style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: '5%', left: '3%' }}>
                  <FormControl>
                    <InputLabel id="period-select-label" className={classes.selectListLabel}>
                      Період
                    </InputLabel>
                    <Select
                      className={classes.selectList}
                      value={selectValue}
                      onChange={handlePeriodChange}
                      label="Період"
                      labelId="period-select-label"
                    >
                      <MenuItem divider autoFocus value={Period.THREEMONTH}>
                        За останні три місяці
                      </MenuItem>
                      <MenuItem divider value={Period.TODAY}>
                        За сьогодні
                      </MenuItem>
                      <MenuItem divider value={Period.WEEK}>
                        За останній тиждень
                      </MenuItem>
                      <MenuItem divider value={Period.MONTH}>
                        За останній місяць
                      </MenuItem>
                      <MenuItem divider value={Period.YEAR}>
                        За останній рік
                      </MenuItem>
                      <MenuItem value={Period.CUSTOM}>Визначити власний період</MenuItem>
                      <MenuItem disabled hidden value={Period.CHOOSE}>
                        Оберіть період
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <Paper className={!darkMode ? classes.paperLight : classes.paperDark}>
                  {ordersOfChoosePeriod ? (
                    <Line
                      height={200}
                      options={options}
                      data={chartMovement}
                      legend={{ display: false }}
                      redraw
                    />
                  ) : (
                    <>
                      <Typography className={classes.message}>
                        На жаль, за обраний період замовлень не було
                        <SentimentVeryDissatisfiedIcon
                          fontSize="medium"
                          className={darkMode ? classes.cardIconLight : classes.cardIconDark}
                        />
                        <br />
                        Працюйте краще, і у вас все вийде
                        <SentimentSatisfiedAlt
                          fontSize="medium"
                          className={darkMode ? classes.cardIconLight : classes.cardIconDark}
                        />
                      </Typography>
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <DateSelectionModal
        open={openCalendars}
        onClose={() => setOpenCalendars(false)}
        setCustomDate={setCustomDate}
      />
    </div>
  );
};

export default DashboardContent;
