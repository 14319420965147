import { useSelector, useDispatch } from 'react-redux';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { updateProductInOrderRequest } from '../../../store/actions/orders.actions';
import AppDataTable from '../../../components/AppDataTable/AppDataTable';
import OrdersEditQuantity from '../../../components/Tables/Orders/OrdersEditQuantity';
import OrdersItemTableHeader from './OrdersItemTableHeader';
import { ICurrentOrder } from '../../../interfaces/IOrders';
import { RootState } from '../../../store/store';
import './OrdersItemTable.scss';
// import OrdersParcelNumber from './OrdersParcelNumber';
import { COLORS } from '../../../values/colors';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import OrdersSizeSelector from './OrdersSizeSelector';
import OrdersColorSelector from './OrdersColorSelector';
import { failSnackBar } from '../../../store/actions/snackbar.actions';
import { OrderData } from './order-item-table.type';
import OrderCopyTipsItem from './OrderCopyTipsItem';

interface OrdersItemTableProps {
  order: ICurrentOrder;
}

const OrdersItemTable: FC<OrdersItemTableProps> = ({ order }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state: RootState) => state.orders.loading);
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const currentOrderData: OrderData[] = order.productToOrder.map((item) => {
    return {
      ...item,
      delivery: order.delivery,
      liqpayPaymentStatus: order.liqpayPaymentStatus,
    } as OrderData;
  });

  const linkToProductGenerator = (row) => {
    if (row.product?.shopKey === 'letsShop') {
      return `https://letsshop.com.ua/search/?search=${row.product.nameInProvider}`;
    } else if (row.product?.shopKey === 'fashionGirl') {
      return `https://fashion-girl.ua/ua/${row.product.key}.html`;
    } else if (row.product?.shopKey === 'pobedov') {
      return `https://pobedov.com${row.product.nameInProvider}/`;
    } else if (row.product?.shopKey === 'whiteMandarin') {
      return `${row.product.nameInProvider}/`;
    } else if (row.product?.shopKey === 'sewel') {
      return `${row.product.nameInProvider}`;
    }
  };

  const handleChange = (e, field: string, productToOrderId: number) => {
    dispatch(updateProductInOrderRequest(e.target.value, field, productToOrderId, order.id));
  };

  const handleCopyTips = (tipText: string | undefined) => {
    if (!tipText) return;

    navigator.clipboard.writeText(tipText);
  };

  const handleNavigate = (id: number): void => history.push(`/product/${id}`);
  const columns = [
    {
      name: 'productID',
      selector: (row) => row.product.id,
      sortable: true,
      maxWidth: '80px',
      minWidth: '60px',
      cell: (row) => (
        <div className="cell" onClick={() => handleNavigate(row.productId)}>
          {row.productId}
        </div>
      ),
    },
    {
      name: 'Створено',
      selector: (row) => row.createdAt,
      sortable: true,
      maxWidth: '100px',
      minWidth: '60px',
      format: (row) => {
        return new Date(row.createdAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        });
      },
    },
    {
      name: 'Оновлено',
      selector: (row) => row.updatedAt,
      sortable: true,
      maxWidth: '100px',
      minWidth: '60px',
      format: (row) => {
        return new Date(row.updatedAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'numeric',
          year: 'numeric',
        });
      },
    },
    {
      name: 'Товар',
      selector: (row) => row.product?.name,
      sortable: true,
      // maxWidth: '140px',
      // minWidth: '100px',
      cell: (row) => (
        <div className="cell" onClick={() => handleNavigate(row.productId)}>
          {row.product?.name ? row.product.name : '-'}
          <div>{row.product?.name ? row.product.name : '-'}</div>
        </div>
      ),
    },
    {
      name: 'На сторінку постачальника',
      selector: (row) => row.product?.name,
      sortable: false,
      // maxWidth: '110px',
      // minWidth: '80px',
      cell: (row) => (
        <a href={linkToProductGenerator(row)} target="_blank" rel="noreferrer">
          <div className="toProduct">
            <ArrowForwardIcon
              style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }}
              fontSize="large"
            />
          </div>
        </a>
      ),
    },
    {
      name: 'Група',
      selector: (row) => row.product?.category?.name,
      sortable: true,
      // maxWidth: '100px',
      // minWidth: '60px',
      cell: (row) => (
        <div className="cell">
          {row.product?.category?.name ? row.product.category.name : '-'}
          <div>{row.product?.category?.name ? row.product.category.name : '-'}</div>
        </div>
      ),
    },
    // {
    //   name: 'Номер ТТН',
    //   selector: (row) => row.parcelNumber,
    //   sortable: true,
    //   // maxWidth: '140px',
    //   // minWidth: '60px',
    //   cell: (row) => {
    //     return <OrdersParcelNumber row={row} orderId={order.id} darkMode={darkMode} />;
    //   },
    // },
    {
      name: 'Область',
      selector: (row) => row.delivery?.areaName,
      sortable: true,
      // maxWidth: '100px',
      // minWidth: '60px',
      cell: (row) => <OrderCopyTipsItem cellText={row.delivery?.areaName} handleCopyTips={handleCopyTips} />,
    },
    {
      name: 'Місто',
      selector: (row) => row.delivery?.cityName,
      sortable: true,
      // maxWidth: '100px',
      // minWidth: '60px',
      cell: (row) => <OrderCopyTipsItem cellText={row.delivery?.cityName} handleCopyTips={handleCopyTips} />,
    },
    {
      name: 'Відділення',
      selector: (row) => row.delivery?.streetName,
      sortable: true,
      // maxWidth: '100px',
      // minWidth: '60px',
      cell: (row) => (
        <OrderCopyTipsItem cellText={row.delivery?.streetName} handleCopyTips={handleCopyTips} />
      ),
    },
    {
      name: 'Спосіб доставки',
      maxWidth: '100px',
      minWidth: '60px',
      selector: (row) => row.delivery?.deliveryMethod,
      sortable: true,
    },
    {
      name: 'Адреса для доставки',
      // maxWidth: '150px',
      // minWidth: '60px',
      selector: (row) => (row.delivery?.courierDeliveryAddress ? row.delivery.courierDeliveryAddress : '-'),
      sortable: true,
      cell: (row) => (
        <div className="cell">
          {row.delivery?.courierDeliveryAddress ? row.delivery.courierDeliveryAddress : '-'}
          <div>{row.delivery?.courierDeliveryAddress ? row.delivery.courierDeliveryAddress : '-'}</div>
        </div>
      ),
    },
    {
      name: 'Ціна',
      maxWidth: '100px',
      minWidth: '80px',
      selector: (row) => row.amount / row.quantity,
      cell: (row) => {
        if (row.amountWithoutDiscount && row.amountWithoutDiscount !== row.amount) {
          return (
            <div style={{ fontSize: '16px', color: 'red', lineHeight: '30px' }}>
              {row.amount / row.quantity}
              <sup style={{ fontSize: '13px', color: 'rgba(0,0,0,0.87)' }}>
                <s>{row.amountWithoutDiscount / row.quantity}</s>
              </sup>
            </div>
          );
        } else {
          return row.amount / row.quantity;
        }
      },
      sortable: true,
    },
    {
      name: 'Кількість',
      selector: (row) => row.quantity,
      sortable: true,
      maxWidth: '100px',
      minWidth: '80px',
      cell: (row) => {
        return <OrdersEditQuantity row={row} orderId={order.id} darkMode={darkMode} />;
      },
    },
    {
      name: 'Сума',
      selector: (row) => row.amount,
      sortable: true,
      maxWidth: '100px',
      minWidth: '80px',
    },
    {
      name: 'Спосіб оплати',
      // maxWidth: '200px',
      // minWidth: '150px',
      selector: (row) =>
        `${
          row.liqpayPaymentStatus && row.liqpayPaymentStatus === 'success' ? 'liqPay' : 'Оплата при отриманні'
        }`,
      sortable: true,
    },
    {
      name: 'Розмір',
      selector: (row) => row.size,
      sortable: false,
      cell: (row) => {
        const arr = row.product?.characteristicValue?.find((el) => el.name === 'Кольори та розміри');
        const sizeByColor = arr?.jsonValue[row.color];

        return (
          <OrdersSizeSelector
            value={row.size}
            handleChange={(e) => handleChange(e, 'size', row.id)}
            menuItems={sizeByColor}
            disabled={loading}
          />
        );
      },
    },
    {
      name: 'Колір',
      selector: (row) => row.color,
      sortable: false,
      cell: function (row) {
        const colorAndSize = getColorsAndSize(row.product.characteristicValue);
        let productToOrder = order.productToOrder
          .filter((el) => el.product.id === row.product.id)
          .find((el) => el.id === row.id);
        let colorsAndSizes = productToOrder?.product.characteristicValue.find(
          (el) => el.name === 'Кольори та розміри'
        )?.jsonValue;
        function colorChange(e) {
          if (colorsAndSizes[e.target.value].includes(productToOrder?.size) || !productToOrder?.size) {
            handleChange(e, 'color', row.id);
          }
          if (productToOrder?.size && !colorsAndSizes[e.target.value].includes(productToOrder?.size)) {
            dispatch(failSnackBar('Немає такого розміру для цього кольору.'));
            return;
          }
        }

        return (
          <OrdersColorSelector
            value={row.color}
            handleChange={(e) => colorChange(e)}
            menuItems={colorAndSize[0]}
            disabled={loading}
          />
        );
      },
    },
  ];

  return (
    <AppDataTable<OrderData>
      data={currentOrderData}
      columns={columns}
      title={<OrdersItemTableHeader order={order} darkMode={darkMode} />}
      onRowClicked={() => {}}
    />
  );
};

function getColorsAndSize(arr): [string[], string[]] {
  let allColors: string[] = [];
  let allSizes: string[] = [];
  for (let index of arr) {
    if (index.name === 'Кольори та розміри') {
      allColors = Object.keys(index.jsonValue);
      allSizes = Object.values(index.jsonValue);
    }
  }
  return [allColors, allSizes];
}

export default OrdersItemTable;
