import { AxiosResponse } from 'axios';

import {
  IDeleteUsersResponse,
  IResponseMessage,
  IUsersResponse,
  IUsersStatistic,
  IUsersOnline,
} from './../interfaces/IUsers';

import {
  IGetTreeCategoriesResponse,
  ITreeCategory,
  IAddTreeCategory,
  IDisableEnableCategory,
  ICharToAdd,
  ISort,
} from '../interfaces/ITreeCategory';

import {
  IAddCharResponse,
  IAddImgResponse,
  IAddProduct,
  IGetProductById,
  IGetProducts,
  IProductCharRequest,
  IUpdateProduct,
  IUpdateAvailabilityProduct,
  IDisableProduct,
  IDeleteProductChars,
  IProductsFilter,
  IUpdateDiscountedPrice,
  IProductStatistic,
  IMessage,
  IShopList,
  IPriceRange,
} from '../interfaces/IProducts';
import { ISearchItems, ISearchItemsResponse } from '../interfaces/ISearch';
import {
  IBasicOrder,
  IStatisticOrders,
  IStatusOrders,
  IOrderAmount,
  IOrdersStatusStatistic,
  IDeleteOrder,
} from '../interfaces/IOrders';
import { TreeCategory } from '../pages/TreeCategories/TreeCategoryInfo/treeCategoryReducer';

import { IActions, IActionsImage } from '../interfaces/actions';
import { ICategoryPriority, ISettingsItem } from '../interfaces/ISettings';
import {
  IDeleteSlideResponse,
  ISlideItem,
  ISlidesResponse,
  ISlideUpdateValues,
  ISlideVisibility,
} from '../interfaces/ISlides';
import { ICommentResponse, ICommentsDateRange, IDeleteCommentResponse } from '../interfaces/IComment';
import { IDeleteFeedbackResponse, IFeedbackResponse } from '../interfaces/IFeedback';
import {
  IUserReqAdd,
  IAuthResponse,
  IUserReqUp,
  IUserCreeds,
  IUserItem,
  IUsersData,
  IuserConfirmEmail,
} from '../interfaces/IUsers';
import instance from './axios-interceptors';
import { Status } from '../enums/orderStatus';
import { IRole } from '../interfaces/IRoles';
import { ISliderAnimation, ISliderAnimations } from '../interfaces/ISliderAnimations';
import { IInvoiceDateRange } from '../interfaces/IInvoice';
import { IParserStatus } from '../interfaces/IParsers';
import { INotification } from '../interfaces/INotification';

type FetchedDataType<T> = Promise<AxiosResponse<T>>;

type ApiFetchedDataType = {
  treeCategories: {
    get: () => FetchedDataType<IGetTreeCategoriesResponse>;
    getById: (id: number) => FetchedDataType<IGetTreeCategoriesResponse>;
    getByName: (name: string) => FetchedDataType<ITreeCategory[]>;
    add: (category: IAddTreeCategory) => FetchedDataType<ITreeCategory>;
    updateStatus: (status: string, userId: number, categoryId: number) => FetchedDataType<IGetProductById>;
    delete: (id: number) => FetchedDataType<JSON>;
    update: (data: TreeCategory) => FetchedDataType<IGetTreeCategoriesResponse>;
    updateParams: (
      status: string,
      categoryId: number,
      currentValues: any
    ) => FetchedDataType<IGetProductById>;
    disableEnable: (data: IDisableEnableCategory) => FetchedDataType<ITreeCategory>;
    addCharToCategory: (data: ICharToAdd) => FetchedDataType<IAddCharResponse>;
    editCharToCategory: (data: ICharToAdd) => FetchedDataType<IAddCharResponse>;
    deleteCharToCategory: (id: number) => FetchedDataType<JSON>;
    sort: (data: ISort[]) => FetchedDataType<JSON>;
  };

  categoryImages: {
    add: (data: FormData) => FetchedDataType<any>;
  };

  notification: {
    getAll: (userId: number) => FetchedDataType<INotification[]>;
    getAllByType: (type: INotification['type']) => FetchedDataType<INotification[]>;
    read: (id: number) => FetchedDataType<INotification>;
  };

  products: {
    get: (
      page: number,
      limit: number,
      sort: string,
      sortDirect: string,
      filter: IProductsFilter
    ) => FetchedDataType<IGetProducts>;
    getById: (id: number) => FetchedDataType<IGetProductById>;
    add: (product: IAddProduct, userId: number) => FetchedDataType<IGetProductById>;
    updateStatus: (
      status: string,
      userId: number | undefined,
      productId: number
    ) => FetchedDataType<IGetProductById>;
    update: (userId: number, product: IUpdateProduct) => FetchedDataType<IGetProductById>;
    updateParams: (status: string, productId: number, currentValues: any) => FetchedDataType<IGetProductById>;
    updateDiscountedPrice: (product: IUpdateDiscountedPrice) => FetchedDataType<IGetProductById>;
    updateImg: (data: FormData) => FetchedDataType<IAddImgResponse>;
    updateImagesOrder: (images: string[]) => FetchedDataType<void>;
    updateMainImg: (data: { productId: number; imgName: string }) => FetchedDataType<IGetProductById>;
    deleteImg: (imgName: string) => FetchedDataType<IGetProductById>;
    deleteProduct: (id: number) => FetchedDataType<JSON>;
    addProductCharValues: (data: IProductCharRequest) => FetchedDataType<IAddCharResponse>;
    updateProductCharValues: (data: IProductCharRequest) => FetchedDataType<IAddCharResponse>;
    deleteProductCharValues: (data: IDeleteProductChars) => FetchedDataType<JSON>;
    updateAvailabilityProduct: (data: IUpdateAvailabilityProduct) => FetchedDataType<IAddCharResponse>;
    disableProduct: (data: IDisableProduct) => FetchedDataType<IAddCharResponse>;
    getProductsSizes: () => FetchedDataType<string[]>;
    getProductStatistic: (productId: number) => FetchedDataType<IProductStatistic>;
    getTopProducts: (startDate, endDate, categoryUrl) => FetchedDataType<any>;
    getParsersProducts: (startDate, endDate) => FetchedDataType<any>;
    getDiscountInfo: () => FetchedDataType<object>;
    getShopList: () => FetchedDataType<IShopList[]>;
    getPriceRange: (filter: object) => FetchedDataType<IPriceRange>;
  };

  settings: {
    get: () => FetchedDataType<ISettingsItem>;
    getParsers: () => FetchedDataType<ISettingsItem>;
    put: (settings: IActions) => FetchedDataType<ISettingsItem>;
    getCategoriesWithPriority: () => FetchedDataType<ITreeCategory>;
    changeCategoriesPriority: (categoryPriority: ICategoryPriority[]) => FetchedDataType<IMessage>;
    generateFeed: () => FetchedDataType<IMessage>;
  };

  slides: {
    get: (page: number, limit: number, sort: string, sortDirect: string) => FetchedDataType<ISlidesResponse>;
    add: (slide: FormData) => FetchedDataType<ISlideItem>;
    update: (slide: ISlideUpdateValues) => FetchedDataType<ISlideItem>;
    updateVisibility: (slide: ISlideVisibility) => FetchedDataType<ISlideItem>;
    delete: (
      slide: IActionsImage,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteSlideResponse>;
    getByDatesRange: (datesArray: string[], id?: string) => FetchedDataType<ISlideItem[]>;
  };

  orders: {
    get: (
      page: number,
      limit: number,
      filter: object,
      sortingInfo: object,
      searchValue: string
    ) => FetchedDataType<IBasicOrder>;
    updateStatus: (id: number, status: Status) => FetchedDataType<IBasicOrder>;
    updatePaymentStatus: (id: number, paymentStatus: boolean) => FetchedDataType<IBasicOrder>;
    update: (
      orderId: number,
      productId: number,
      data: { quantity?: number; color?: string; size?: string }
    ) => FetchedDataType<IBasicOrder>;
    getById: (id: number) => FetchedDataType<IBasicOrder>;
    getByParams: (page: number, limit: number, searchValue: string) => FetchedDataType<IBasicOrder>;
    getByDatesRange: (datesArray: string[]) => FetchedDataType<IStatisticOrders[]>;
    getStatusByDatesRange: (datesArray: string[]) => FetchedDataType<IStatusOrders[]>;
    getStatusStatisticByRange: (datesArray: string[]) => FetchedDataType<IOrdersStatusStatistic[]>;
    updateProductInOrder: (data) => FetchedDataType<IBasicOrder>;
    getNewAmount: (filter: object, searchValue: string | null) => FetchedDataType<IOrderAmount>;
    deleteOrderById: (id: number) => FetchedDataType<IDeleteOrder>;
    acceptCancel: (id: number, status: string) => FetchedDataType<IBasicOrder>;
  };

  comments: {
    get: (page: number, limit: number, sort: string, sortDirect: string) => FetchedDataType<ICommentResponse>;
    getByDatesRange: (datesArray: string[]) => FetchedDataType<ICommentsDateRange[]>;
    delete: (
      id: number,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteCommentResponse>;
  };

  feedbacks: {
    get: (
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IFeedbackResponse>;
    delete: (
      id: number,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteFeedbackResponse>;
  };

  users: {
    get: (
      page: number,
      limit: number,
      sort: string,
      sortDirect: string,
      searchValue: string | null
    ) => FetchedDataType<IUsersResponse>;
    getByDatesRange: (datesArray: string[]) => FetchedDataType<IUsersStatistic>;
    getOnline: () => FetchedDataType<IUsersOnline>;
  };

  user: {
    auth: (user: IUserCreeds) => FetchedDataType<IAuthResponse>;
    get: () => FetchedDataType<IUsersData>;
    add: (user: IUserReqAdd) => FetchedDataType<IUserItem>;
    update: (user: IUserReqUp) => FetchedDataType<IUserItem>;
    delete: (
      id: number,
      page: number,
      limit: number,
      sort: string,
      sortDirect: string
    ) => FetchedDataType<IDeleteUsersResponse>;
    requestPasswordInstall: (data: { email: string }) => FetchedDataType<IResponseMessage>;
    updateUserData: (userData: IUserReqUp) => FetchedDataType<IUserReqUp>;
    deleteAvatar: () => FetchedDataType<IResponseMessage>;
    addAvatar: (data) => FetchedDataType<IResponseMessage>;
    confirmEmail: (data: IuserConfirmEmail) => FetchedDataType<JSON>;
  };

  roles: {
    get: () => FetchedDataType<IRole[]>;
  };

  search: {
    getSearchItems: (fields: ISearchItems) => FetchedDataType<ISearchItemsResponse>;
  };

  sliderAnimations: {
    getSliderAnimations: () => FetchedDataType<ISliderAnimations>;
    getActiveSliderAnimation: () => FetchedDataType<ISliderAnimation>;
    changeActiveSliderAnimation: (id: number, isActive: boolean) => FetchedDataType<ISliderAnimation>;
  };

  invoice: {
    getInvoicesList: (data) => FetchedDataType<JSON>;
    removeInvoice: (name: string) => FetchedDataType<JSON>;
    generateInvoice: (invoiceDateRange: IInvoiceDateRange) => FetchedDataType<JSON>;
    getInvoiceFile: (name: string) => FetchedDataType<Blob>;
  };

  parsers: {
    apiGetParsersStatus: () => Promise<AxiosResponse<IParserStatus[]>>;
  };

  draw: {
    createDraw: (data: object) => FetchedDataType<JSON>;
    getDraws: () => FetchedDataType<JSON>;
    getActiveDraw: () => FetchedDataType<JSON>;
    editDraw: (data: object) => FetchedDataType<JSON>;
    getDrawUsersById: (id: number) => FetchedDataType<JSON>;
    setDrawWinner: (data) => FetchedDataType<JSON>;
  };
  coupon: {
    createCoupon: (data: object) => FetchedDataType<JSON>;
  };
};

export const api: ApiFetchedDataType = {
  treeCategories: {
    get: () => instance.get('/category/tree'),
    getById: (id) => instance.get(`/category/tree/${id}`),
    getByName: (name) => instance.get(`/category/name?name=${name}`),
    add: (category) => instance.post('/category/tree', category),
    updateStatus: (status, userId, categoryId) =>
      instance.patch(`/category/updateStatus/${categoryId}`, { status, userId }),
    update: (data) => {
      return instance.patch('/category/tree', data);
    },
    updateParams: (status, categoryId, currentValues) =>
      instance.patch(`/category/updateParams/${categoryId}`, { status, currentValues }),
    disableEnable: (data) => instance.patch('/category/tree/disablecategories', data),
    delete: (id) => instance.delete(`/category/tree/${id}`),
    addCharToCategory: (data) => instance.post('/characteristics', data),
    editCharToCategory: (data) => instance.patch(`/characteristics/${data.id}`, data),
    deleteCharToCategory: (id) => instance.delete(`/characteristics/${id}`),
    sort: (data) => instance.post('/category/tree-sort', data),
  },

  notification: {
    getAll: (userId) => instance.get(`/notification/${userId}`),
    getAllByType: (type: INotification['type']) => instance.get(`/notification/${type}`),
    read: (notificationId: number) => instance.put(`/notification/read/${notificationId}`),
  },

  categoryImages: {
    add: (data) => instance.post('/category/category-images', data),
  },

  products: {
    get: (page, limit, sort, sortDirect, filter) => {
      let url: string = `/product/admin?page=${page}&limit=${limit}`;
      if (sort) url = url + `&sort=${sort}`;
      if (sortDirect) url = url + `&sortDirect=${sortDirect}`;
      if (filter?.id) url = url + `&filterId=${filter.id}`;
      if (filter?.name) url = url + `&filterName=${filter.name}`;
      if (filter?.category) url = url + `&filterCategory=${filter.category}`;
      if (filter?.price?.[0]) url = url + `&filterPrice=${filter.price}`;
      if (filter?.shop) url = url + `&filterShop=${filter.shop}`;
      if (filter?.size) url = url + `&filterSize=${filter.size}`;
      if (filter?.disabled) url = url + `&filterDisabled=${filter.disabled}`;
      if (filter?.discounted) url = url + `&filterDiscounted=${filter.discounted}`;
      if (filter?.availability) url = url + `&filterAvailability=${filter.availability}`;
      return instance.get(url);
    },
    getById: (id) => instance.get(`/product/${id}`),
    add: (product, userId) => instance.post('/product', { product, userId }),
    updateStatus: (status, userId, productId) =>
      instance.patch(`/product/updateStatus/${productId}`, { status, userId }),
    updateImg: (data) => instance.post('/product/multipleimages', data),
    addProductCharValues: (data) => instance.post('/characteristics-values', data),
    update: (userId, { id, ...product }) => instance.patch(`/product/${id}`, { product, userId }),
    updateParams: (status, productId, currentValues) =>
      instance.patch(`/product/updateParams/${productId}`, { status, currentValues }),
    updateImagesOrder: (images) => instance.post('/product/img/reorder', { images }),
    updateDiscountedPrice: ({ productId, userId, ...product }) =>
      instance.patch(`/product/${productId}`, { product, userId }),
    updateMainImg: (data) => instance.patch('/product/img/preview', data),
    updateProductCharValues: (data) => instance.patch('/characteristics-values', data),
    updateAvailabilityProduct: ({ productId, userId, ...product }) =>
      instance.patch(`/product/${productId}`, { product, userId }),
    disableProduct: ({ productId, userId, ...product }) =>
      instance.patch(`/product/${productId}`, { product, userId }),
    deleteProductCharValues: (data) => instance.delete('/characteristics-values', { data }),
    deleteImg: (imgName) => instance.delete(`/product/img/${imgName}`),
    deleteProduct: (id) => instance.delete(`/product/${id}`),
    getProductsSizes: () => instance.get('/characteristics-values/filtered-sizes'),
    getProductStatistic: (productId) => instance.get(`/product-statistic/${productId}`),
    getTopProducts: (startDate, endDate, categoryUrl) =>
      instance.get(`/product-statistic`, { params: { startDate, endDate, categoryUrl } }),
    getParsersProducts: (startDate, endDate) =>
      instance.get(`/orders/parsers`, { params: { startDate, endDate } }),
    getDiscountInfo: () => instance.get('/product/discountInfo'),
    getShopList: () => instance.get('/shop-parser/shopList'),
    getPriceRange: (filter) => instance.get('/product/priceRange', { params: { ...filter } }),
  },

  slides: {
    get: (page, limit, sort, sortDirect) =>
      instance.get(`/slide?page=${page}&limit=${limit}&sort=${sort}&sortDirect=${sortDirect}`),
    add: (slide) => instance.post(`/slide`, slide),
    update: (slide) => instance.patch(`/slide/${slide.id}`, slide.body),
    updateVisibility: (slide) => instance.patch(`/slide/visibility/${slide.id}`, { isShown: slide.isShown }),
    delete: (slide, page, limit, sort, sortDirect) =>
      instance.post(`/slide/${slide.id}`, { page, limit, sort, sortDirect }),
    getByDatesRange: (datesArray: string[], id?: string) =>
      instance.get(
        `/slide-statistic?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}${
          id ? '&id=' + id + '' : ''
        }`
      ),
  },

  settings: {
    get: () => instance.get('/parameters'),
    getParsers: () => instance.get('/parameters/parser'),
    put: (settings) => instance.put('/parameters', settings),
    getCategoriesWithPriority: () => instance.get('/category/with-priority'),
    changeCategoriesPriority: (categoryPriority) =>
      instance.post('/category/change-priority', categoryPriority),
    generateFeed: () => instance.get('/shop-parser/feed'),
  },

  orders: {
    get: (page, limit, filter, sortingInfo, searchValue) =>
      instance.get('/orders', { params: { page, limit, ...filter, ...sortingInfo, searchValue } }),
    getById: (id) => instance.get(`/orders/${id}`),
    getByParams: (page, limit, searchValue) =>
      instance.get(`/orders/params?page=${page}&limit=${limit}&searchValue=${searchValue}`),
    getByDatesRange: (datesArray: string[]) =>
      instance.get(`/orders/statistic?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    getStatusByDatesRange: (datesArray: string[]) =>
      instance.get(`/orders/status?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    getStatusStatisticByRange: (datesArray: string[]) =>
      instance.get(`/orders/statistic-dashboard?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    updateStatus: (id, status) => instance.patch(`/orders/status/${id}`, status),
    updatePaymentStatus: (id, paymentStatus) => instance.patch(`/orders/payment-status/${id}`, paymentStatus),
    update: (orderId, productId, data) => instance.put(`/orders/${orderId}/${productId}`, data),
    updateProductInOrder: (data) => instance.put('/orders/product/', data),
    getNewAmount: (filter: object, searchValue: string | null) =>
      instance.get('/orders/amounts', { params: { ...filter, searchValue } }),
    deleteOrderById: (orderId: number) => instance.delete(`/orders/${orderId}`),
    acceptCancel: (orderId, status) => instance.patch(`/orders/acceptCancel/${orderId}`, { status }),
  },

  users: {
    get: (page, limit, sort, sortDirect, searchValue) =>
      instance.get('/users', { params: { page, limit, sort, sortDirect, searchValue } }),
    getByDatesRange: (datesArray: string[]) =>
      instance.get(`/users/statistic?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    getOnline: () => instance.get('/users/online'),
  },

  user: {
    get: () => instance.get('/users/profile'),
    auth: (user) => instance.post('/auth/admin/login', user),
    add: (user) => instance.post('/auth/register-through-admin', user),
    addAvatar: (data) => instance.post('users/avatar/add', data),
    confirmEmail: (data) => instance.post('users/newEmail/set', data),
    requestPasswordInstall: (email) => instance.post('/users/password/reset', email),
    updateUserData: (userData) => instance.patch('/users/update', userData),
    update: ({ id, ...user }) => instance.put(`/users/${id}`, user),
    delete: (id, page, limit, sort, sortDirect) =>
      instance.post(`/users/${id}`, { page, limit, sort, sortDirect }),
    deleteAvatar: () => instance.delete('users/avatar'),
  },

  comments: {
    get: (page, limit, sort, sortDirect) =>
      instance.get(`/comments?page=${page}&limit=${limit}&sort=${sort}&sortDirect=${sortDirect}`),
    getByDatesRange: (datesArray: string[]) =>
      instance.get(`/comments/statistic?dateRange[0]=${datesArray[0]}&dateRange[1]=${datesArray[1]}`),
    delete: (id, page, limit, sort, sortDirect) =>
      instance.post(`/comments/admin/${id}`, { page, limit, sort, sortDirect }),
  },

  feedbacks: {
    get: (page, limit, sort, sortDirect) =>
      instance.get(`/feedbacks?page=${page}&limit=${limit}&sort=${sort}&sortDirect=${sortDirect}`),
    delete: (id, page, limit, sort, sortDirect) =>
      instance.post(`/feedbacks/admin/${id}`, { page, limit, sort, sortDirect }),
  },

  roles: {
    get: () => instance.get('/roles'),
  },

  search: {
    getSearchItems: (fields) =>
      instance.get(
        `/search/admin?${fields.option}=${fields.query}&page=${fields.page}&limit=${fields.limit}`
      ),
  },

  sliderAnimations: {
    getSliderAnimations: () => instance.get('/slider-animations'),
    getActiveSliderAnimation: () => instance.get('/slider-animations/active'),
    changeActiveSliderAnimation: (id: number, isActive: boolean) =>
      instance.patch(`/slider-animations/change-active/${id}/${isActive}`),
  },

  invoice: {
    getInvoicesList: (params) => instance.get('/invoice', { params }),
    getInvoiceFile: (name: string) => instance.get(`/invoice/${name}`, { responseType: 'blob' }),
    generateInvoice: (invoiceDateRange: IInvoiceDateRange) => instance.post('/invoice', invoiceDateRange),
    removeInvoice: (name: string) => instance.delete(`/invoice/${name}`),
  },

  parsers: {
    apiGetParsersStatus: () => instance.get('/shop-parser/status'),
  },

  draw: {
    createDraw: (data) => instance.post('/raffle', data),
    getDraws: () => instance.get('/raffle'),
    getActiveDraw: () => instance.get('/raffle/active'),
    editDraw: (data) => instance.patch(`/raffle/${data.id}`, data),
    getDrawUsersById: (id: number) => instance.get(`/raffle/${id}`),
    setDrawWinner: (data: { raffleId: number; userId: number }) =>
      instance.patch(`/raffle/winner/${data.raffleId}/${data.userId}`, { winner: 'true' }),
  },
  coupon: {
    createCoupon: (data) => instance.post('/coupons', data),
  },
};
