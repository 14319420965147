import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import StatisticsButtons from './StatisticsButton';
import StatisticsBlock from './StatisticsBlock';
import { StatisticActionContext } from '../../context/staistic-action-context';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { AppDispatch } from '../../store/store';
import { getUsersOnlineRequest } from '../../store/actions/users.actions';
import { IUsersOnline } from '../../interfaces/IUsers';

const useStyles = makeStyles({
  statisticsSection: {
    display: 'block',
    width: '100%',
    marginTop: '15px',
  },
  '@media(max-width: 475px)': {
    statisticsSection: {
      marginTop: '50px',
    }
  },
  onlineUsers: {
    width: '100%',
  },
  onlineUsersText: {
    display: 'block',
    width: 'auto',
    margin: '15px auto 0 30px',
    textAlign: 'left',
  },
  usersCounter: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: '1.75',
    letterSpacing: '0.02857em',
    fontSize: '0.875rem',
  },
});

interface IDataChart {
  date: string;
  value: string;
  valueSecond?: string;
}

const StatisticsSection: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const styles = useStyles();
  const [action, setAction] = useState(null);
  const [chartData, setChartData] = useState<IDataChart[] | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<null | IUsersOnline>(null);
  const numberUsers = useSelector((state: RootState) => state.users.onlineUsers);

  useEffect(() => {
    dispatch(getUsersOnlineRequest());
  }, []);

  useEffect(() => {
    if (numberUsers) {
      setOnlineUsers(numberUsers);
    }
  }, [numberUsers]);


  return (
    <StatisticActionContext.Provider value={{ action, setAction }}>
      <div className={styles.statisticsSection}>
        <div className={styles.onlineUsers}>
          <p className={styles.onlineUsersText}>
            Зараз користувачів на сайті:
            <span className={styles.usersCounter}> {onlineUsers}</span>
          </p>
        </div>
        <StatisticsButtons  chartData ={chartData} setChartData={setChartData}/>
        <StatisticsBlock chartData ={chartData} setChartData={setChartData}/>
      </div>
    </StatisticActionContext.Provider>
  );
};

export default StatisticsSection;
