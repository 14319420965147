import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsRequest } from '../../../store/actions/products.actions';
import { ProductsTableProps } from '../../../interfaces/IProducts';
import DateMoment from '../../Common/Date-moment';
import { root } from '../../../api/config';
import { priceFormat } from '../../../utils/priceFormat';
import styles from './ProductsTable.module.scss';
import { cols } from '../../../pages/Products/ProductsPage';
import AppProductsDataTable from '../../AppDataTable/AppProductsDataTable';
import { SearchResultHighlight } from '../../Common/SearchResultHighlight';
import { RootState } from '../../../store/store';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { COLORS } from '../../../values/colors';

const placeholder = `${root}/static/uploads/empty-preview.png`;

const MainImgName = () => (
  <p className={styles['table-header-cell']}>
    <span>Головне</span>
    <span>зображення</span>
  </p>
);

const ProductsTable: React.FC<ProductsTableProps> = ({
  list,
  activeColumns,
  isSearch,
  filter,
  count,
  paginationPage,
  paginationLimit,
  sort,
  sortDirect,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultSortFieldId = Object.keys(cols).indexOf(sort) + 1;
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const regExp = filter.name ? new RegExp(filter.name, 'ig') : null;

  const linkToProductGenerator = (row) => {
    console.log(row)
    if (row.shopKey === 'letsShop') {
      return `https://letsshop.com.ua/search/?search=${row.nameInProvider}`;
    } else if (row.shopKey === 'fashionGirl') {
      return `https://fashion-girl.ua/ua/${row.key}.html`;
    } else if (row.shopKey === 'pobedov') {
      return `https://pobedov.com${row.nameInProvider}/`;
    } else if (row.shopKey === 'whiteMandarin') {
      return `${row.nameInProvider}/`;
    } else if (row.shopKey === 'sewel') {
      return `${row.nameInProvider}`;
    }
  };

  const productsColumns = [
    {
      name: 'ID',
      selector: (row) => row.id,
      sortable: true,
      maxWidth: '6%',
      minWidth: '5%',
      cell: (row) => {
        let newData: React.JSX.Element | null = null;
        if (isSearch && filter.id) {
          const regExp = new RegExp(filter.id.toString(), 'ig');
          newData = <SearchResultHighlight data={row.id} filter={filter.id.toString()} regExp={regExp} />;
        }

        return <>{newData || row.id}</>;
      },
      omit: !activeColumns.includes('ID'),
    },
    {
      name: <MainImgName />,
      selector: (row) => row.mainImg,
      format: (row) =>
        row.mainImg ? (
          <div className={styles.mainImg}>
            <img src={`${root}/static/uploads/${row.mainImg.name}`} alt={row.mainImg.name} />
          </div>
        ) : (
          <div className={styles.mainImg}>
            <img src={placeholder} alt="placeholder" />
          </div>
        ),
      maxWidth: '8%',
      minWidth: '7%',
      omit: !activeColumns.includes('Головне зображення'),
    },
    {
      name: 'Назва',
      selector: (row) => row.name,
      sortable: true,
      minWidth: '200px',
      cell: (row) => {
        let data: string = row.name.length <= 30 ? row.name : `${row.name.slice(0, 30)}...`;
        let newData: React.JSX.Element | null = null;
        if (isSearch && filter.name && regExp) {
          newData = <SearchResultHighlight data={data} filter={filter.name} regExp={regExp} />;
        }

        return (
          <Link
            to={{
              pathname: `/product/${row.id}`,
              state: { from: '/product' },
            }}
          >
            <span className={styles['product-name']}>{newData ? newData : data}</span>
          </Link>
        );
      },
      omit: !activeColumns.includes('Назва'),
    },
    {
      name: 'На сторінку постачальника',
      selector: (row) => row.product?.name,
      sortable: false,
      // maxWidth: '110px',
      // minWidth: '80px',
      cell: (row) => (
        <a href={linkToProductGenerator(row)} target="_blank" rel="noreferrer">
          <div className="toProduct">
            <ArrowForwardIcon
              style={darkMode ? { color: COLORS.darkGreen } : { color: COLORS.primaryGreen }}
              fontSize="large"
            />
          </div>
        </a>
      ),
    },
    {
      name: 'Ціна',
      selector: (row) => row.discountedPrice || row.price,
      sortable: true,
      format: (row) =>
        row.discountedPrice && row.isActiveDiscount ? (
          <>
            <s>
              <span>&#8372; {priceFormat(row.price)}</span>
            </s>
            <div style={{ color: 'red' }}>
              <span>&#8372; {priceFormat(row.discountedPrice)}</span>
            </div>
          </>
        ) : (
          <span>&#8372; {priceFormat(row.price)}</span>
        ),
      omit: !activeColumns.includes('Ціна'),
    },
    {
      name: 'Опис',
      selector: (row) => row.description,
      wrap: true,
      sortable: true,
      minWidth: '150px',
      format: (row) =>
        row.description?.length <= 30
          ? row.description
          : row.description
          ? `${row.description?.slice(0, 30)}...`
          : (row.description = 'Опис відсутній'),
      omit: !activeColumns.includes('Опис'),
    },
    {
      name: 'Категорія',
      selector: (row) => (row.category?.name ? row.category.name : 'Без категорії'),
      sortable: true,
      minWidth: '5%',
      omit: !activeColumns.includes('Категорія'),
    },
    {
      name: 'URL ключ',
      selector: (row) => row.key,
      minWidth: '250px',
      cell: (row) => {
        let newData: React.JSX.Element | null = null;
        if (isSearch && filter.name && regExp) {
          newData = <SearchResultHighlight data={row.key} filter={filter.name} regExp={regExp} />;
        }

        return <>{newData || row.key}</>;
      },
      omit: !activeColumns.includes('URL ключ'),
    },
    {
      name: 'Магазин',
      selector: (row) => row.shopKey,
      minWidth: '7%',
      maxWidth: '8%',
      sortable: true,
      omit: !activeColumns.includes('Магазин'),
    },
    {
      name: 'Створено',
      selector: (row) => row.createdAt,
      sortable: true,
      format: (row) => <DateMoment date={row.createdAt} column />,
      omit: !activeColumns.includes('Створено'),
    },
    {
      name: 'Оновлено',
      selector: (row) => row.updatedAt,
      sortable: true,
      format: (row) => <DateMoment date={row.updatedAt} column />,
      omit: !activeColumns.includes('Оновлено'),
    },
  ];

  const onRowClicked = (id: number) => {
    history.push(`/product/${id}`);
  };

  const setSortColumn = (column: any, direction: any) => {
    const fieldName = Object.keys(cols)[Object.values(cols).indexOf(column.name)];
    dispatch(getProductsRequest(paginationPage, paginationLimit, fieldName, direction, filter));
  };

  const onChangeLimit = (limit: any) => {
    const newPage = Math.ceil(((paginationPage - 1) * paginationLimit + 1) / limit);
    dispatch(getProductsRequest(newPage, limit, sort, sortDirect, filter));
  };

  return (
    <AppProductsDataTable
      data={list}
      columns={productsColumns}
      title={isSearch ? 'Результати пошуку' : 'Продукти'}
      onRowClicked={(row) => onRowClicked(row.id)}
      count={count}
      setLimit={(limit) => onChangeLimit(limit)}
      setSortColumn={(column, direction) => setSortColumn(column, direction)}
      paginationServer={true}
      paginationPage={paginationPage}
      limit={paginationLimit}
      defaultSortFieldId={defaultSortFieldId}
      sortDirect={sortDirect}
    />
  );
};

export default ProductsTable;
