import React from 'react';
import { Card } from '@material-ui/core';
import DataTable from 'react-data-table-component';

export interface ProductsTopTableProps {
  list: any[];
}

const StatisticParsers: React.FC<ProductsTopTableProps> = ({ list }) => {
  //   const [paginationPage, setPaginationPage] = useState(1);
  //   const [paginationLimit, setPaginationLimit] = useState(5);
  // console.log(list);
  const parsers = ['pobedov', 'letsShop', 'fashionGirl', 'olla', 'sewel', 'whiteMandarin'];

  const initialStats = parsers.reduce((acc, parser) => {
    acc[parser] = {
      shop: parser,
      totalorders: '0',
      completedorders: '0',
      cancelledorders: '0',
      pendingorders: '0',
      totalproducts: '0',
    };
    return acc;
  }, {});

  list.forEach((stat) => {
    if (initialStats[stat.shop]) {
      initialStats[stat.shop] = { ...initialStats[stat.shop], ...stat };
    }
  });

  const updatedStatistics = Object.values(initialStats).sort((a, b) => b.totalorders - a.totalorders);

  // console.log(updatedStatistics);

  const productsColumns = [
    {
      name: 'Parser-Shop',
      selector: (row) => row.shop,
      sortable: true,
      maxWidth: '40%',
      minWidth: '20%',
    },
    {
      name: 'Total Orders',
      selector: (row) => row.totalorders,
      sortable: true,
      maxWidth: '10%',
      minWidth: '6%',
    },
    {
      name: 'Completed',
      selector: (row) => row.completedorders,
      sortable: true,
      maxWidth: '10%',
      minWidth: '6%',
    },
    {
      name: 'Cancelled',
      selector: (row) => row.cancelledorders,
      sortable: true,
      maxWidth: '10%',
      minWidth: '6%',
    },
    {
      name: 'Pending',
      selector: (row) => row.pendingorders,
      maxWidth: '10%',
      minWidth: '6%',
    },
    {
      name: 'Total Products',
      selector: (row) => row.totalproducts,
      maxWidth: '0%',
      minWidth: '6%',
    },
  ];

  //   const handlePageChange = (page: number) => {
  //     setPaginationPage(page);
  //   };

  //   const handleRowsPerPageChange = (newLimit: number) => {
  //     setPaginationLimit(newLimit);
  //     setPaginationPage(1);
  //   };

  return (
    <Card>
      <DataTable
        style={{ backgroundColor: '#000' }}
        data={updatedStatistics}
        columns={productsColumns}
        title={'Топ Parser-Shop'}
        // pagination
        // paginationPerPage={paginationLimit}
        // paginationTotalRows={list.length}
        // paginationDefaultPage={paginationPage}
        // paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50, 100]}
        // onChangePage={handlePageChange}
        // onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </Card>
  );
};

export default StatisticParsers;
